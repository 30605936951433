import styles from "./index.module.scss";
import {
  StackLayout,
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
import Image from "next/image";
import Link from "next/link";

const DashboardTile = ({ name, content, iconSrc, linkUrl }) => {
  return (
    <Link href={linkUrl}>
      <StackLayout orientation="vertical" className={styles.spotlightTile}>
        <Image alt={`${name} Icon`} height={100} width={100} src={iconSrc} />
        <h2>{name}</h2>
        <p>{content}</p>
      </StackLayout>
    </Link>
  );
};

const homepageConfig = {
  hero: {
    header: "Welcome to Thrasio Tools",
    subtext:
      "Explore a growing collection of reports and tools to help streamline your day-to-day workflows. Watch this space for updates as new features and dashboards get added!",
  },

  explore: {
    header: "Explore Thrasio Tools",
    subtext: "",
  },

  dashboardTiles: [
    {
      name: "Hourly Reporting",
      content:
        "Consolidated, timely performance reporting to monitor critical KPIs",
      linkUrl: "/dashboards/powerbi/hourly-report",
      iconSrc: "/icon_holiday-reports.png",
    },
    {
      name: "Product Diagnostics",
      content:
        "Diagnose product/brand performance and key trends for a specified date range",
      linkUrl: "/dashboards/powerbi/product-diagnostics",
      iconSrc: "/icon_product-diagnostics.png",
    },
    {
      name: "Inventory Tracker",
      content:
        "Monitor product inventory levels and days forward coverage by product and region",
      linkUrl: "/dashboards/powerbi/inventory-tracker",
      iconSrc: "/icon_inventory-tracker.png",
    },
  ],
};

function Homepage() {
  const rowHeight = 20;
  const maxRows = 3;

  const maxColumnCount = 3;
  const columnCount = Math.min(
    maxColumnCount,
    homepageConfig.dashboardTiles.length
  );
  const columnWidth = `calc(100% / ${columnCount})`;

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.content}>
        <div className={styles.hero}>
          <div>
            <h1 className={styles.heroHeader}>{homepageConfig.hero.header}</h1>
            <p className={styles.heroSubtext}>{homepageConfig.hero.subtext}</p>
          </div>
        </div>
        <div className={styles.explore}>
          <h1>{homepageConfig.explore.header}</h1>
          <p>{homepageConfig.explore.subtext}</p>
        </div>

        <GridLayout
          align={{
            horizontal: "stretch",
            vertical: "stretch",
          }}
          rows={[...Array(maxRows)].map(() => ({ height: rowHeight }))}
          cols={[...Array(columnCount)].map(() => ({ width: columnWidth }))}
          gap={{ rows: 256, cols: 5 }}
          className={styles.spotlightTools}
        >
          {homepageConfig.dashboardTiles.map(({ ...props }, i) => (
            <GridLayoutItem key={i}>
              <DashboardTile {...props} />
            </GridLayoutItem>
          ))}
        </GridLayout>
      </div>
    </div>
  );
}

export default Homepage;
